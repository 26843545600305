import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from './logo/Amplify-icon.png';

const createClasses = makeStyles({
 logo: { marginLeft: 'auto', marginRight: 'auto', width: 'auto'}
})

const LogoIcon = () => {
  const classes = createClasses();
  return <img className={classes.logo} src={logo} />;
};

export default LogoIcon;

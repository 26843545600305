import React from 'react';
import { makeStyles } from '@material-ui/core';
import devops from './DevOps-icon.png';

const createClasses = makeStyles({
 logo: { marginLeft: 'auto', marginRight: 'auto', width: 'auto'}
})

const DevOpsIcon= () => {
  const classes = createClasses();
  return <img className={classes.logo} src={devops} />;
};

export default DevOpsIcon;
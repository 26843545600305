import { homePlugin } from '@backstage/plugin-home';
import { createReactExtension } from '@backstage/core-plugin-api';

export const ReleaseNotesComponent = homePlugin.provide(
  createReactExtension({
    name: 'ReleaseNotesComponent',
    component: {
      lazy: () =>
        import('./ReleaseNotesWidget').then(m => m.default),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: { defaultColumns: 2, minColumns: 2 },
          height: { defaultRows: 2, minRows: 2 },
        },
      },
    },
  }),
);

export default ReleaseNotesComponent;

import {
  CustomHomepageGrid,
  HomePageStarredEntities,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  WelcomeTitle,
  FeaturedDocsCard,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';
import AzureIcon from './Toolkit/Icons/AzureIcon';
import DevOpsIcon from './Toolkit/Icons/DevOpsIcon';
import SonarQubeIcon from './Toolkit/Icons/SonarQubeIcon';
import { MicrosoftCalendarCard } from '@backstage-community/plugin-microsoft-calendar';
import ReleaseNotesComponent from './ReleaseNotes/ReleaseNotesComponent';
import ToolkitComponent from './Toolkit/ToolkitComponent'

import ADOProjectsWidget from './ADOProjects/ADOProjectsWidget';
import MyticketsWidget from './MyTickets/MyticketsWidget';
import PullRequestsWidget from './PullRequests/PullRequestWidget';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

// const clockConfigs: ClockConfig[] = [
//   {
//     label: 'NYC',
//     timeZone: 'America/New_York',
//   },
//   {
//     label: 'UTC',
//     timeZone: 'UTC',
//   },
//   {
//     label: 'IND',
//     timeZone: 'Asia/Kolkata',
//   },
// ];

// const timeFormat: Intl.DateTimeFormatOptions = {
//   hour: '2-digit',
//   minute: '2-digit',
//   hour12: false,
// };

const defaultConfig = [
  {
    component: 'MyTicketsWidget',
    x: 0,
    y: 0,
    width: 2.5,
    height: 4,
  },
  {
    component: 'ADOProjectsWidget',
    x: 2.5,
    y: 0,
    width: 2.5,
    height: 4,
  },
  {
    component: 'PullRequestsWidget',
    x: 5,
    y: 0,
    width: 2.5,
    height: 4,
  },
  {
    component: 'ReleaseNotesComponent',
    x: 7.5,
    y: 0,
    width: 2.5,
    height: 14,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 0,
    y: 5,
    width: 7.5,
    height: 5,
  },
  {
    component: 'FeaturedDocsCard',
    x: 0,
    y: 5,
    width: 3.75,
    height: 5,
  },
  {
    component: 'ToolkitComponent',
    x: 3.75,
    y: 5,
    width: 3.75,
    height: 5,
  },
];


export const HomePage = () => {
  const classes = useStyles();
  const languages = ['English'];
  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle language = {languages} />} pageTitleOverride="Home">
        <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
      </Header>
      <Content>
        <CustomHomepageGrid config={defaultConfig}>
          <HomePageStarredEntities />
            <FeaturedDocsCard
              filter={{
                  'spec.type': 'doc',
                  'metadata.name': 'Amplifi_Document',
                }}
              />
          <ToolkitComponent  
            tools={[
                {
                  url: 'https://dev.azure.com/',
                  label: 'Azure DevOps',
                  icon: <DevOpsIcon />,
                },
                {
                  url: 'https://portal.azure.com/',
                  label: 'Azure Portal',
                  icon: <AzureIcon />,
                },
                {
                  url: 'https://www.sonarqube.gwsweb.net/',
                  label: 'Sonarqube',
                  icon: <SonarQubeIcon />,
                },
              ]}
            />
          <HomePageTopVisited />
          <ReleaseNotesComponent />
          <MicrosoftCalendarCard />
          <ADOProjectsWidget />
          <MyticketsWidget />
          <PullRequestsWidget />
          <HomePageRecentlyVisited />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );
};

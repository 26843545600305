import {
  MicrosoftAuth,
  OktaAuth,
} from '@backstage/core-app-api';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  useApi,
  configApiRef,
  createApiFactory,
  oktaAuthApiRef,
  discoveryApiRef,
  oauthRequestApiRef,
  microsoftAuthApiRef,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import {
  VisitsWebStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';
import {
  MicrosoftCalendarApiClient,
  microsoftCalendarApiRef,
} from '@backstage-community/plugin-microsoft-calendar';
import {
  // ...
  fetchApiRef,
  // ...
} from '@backstage/core-plugin-api';

export const configsBackend = async() => {
  const  config  = useApi(configApiRef);
  const backendUrl =  config.getString('backend.baseUrl');
  return backendUrl;
};

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: microsoftAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      MicrosoftAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
		defaultScopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation', 'offline_access'],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: oktaAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OktaAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
		defaultScopes: [ "openid", "profile", "offline_access", "email", "groups" ],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  // Or a localStorage data implementation, relies on WebStorage implementation of storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef,
    },
    factory: ({ identityApi, errorApi }) =>
      VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  createApiFactory({
    api: microsoftCalendarApiRef,
    deps: { authApi: microsoftAuthApiRef, fetchApi: fetchApiRef },
    factory: deps => new MicrosoftCalendarApiClient(deps),
  }),
  ScmAuth.createDefaultApiFactory(),
];


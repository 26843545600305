import { homePlugin } from '@backstage/plugin-home';
import { createCardExtension } from '@backstage/plugin-home-react';
import { ToolkitContentProps } from './Content';

export const ToolkitComponent = homePlugin.provide(
  createCardExtension<ToolkitContentProps>({
    name: 'ToolkitComponent',
    title: 'Tools',
    components: () => import('.'),
  }),
);

export default ToolkitComponent;
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useUserProfile } from './useUserProfileInfo';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSidebarOpenState } from '@backstage/core-components';
import styled from "styled-components";
import "./styleguide.css";

const StyledDiv = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  display: flex;
  gap: 6px;
  height: 56px;
  position: relative;
  width: 100%;

  & .fieldset {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 6px;
    padding: var(--spacing-space-4) var(--spacing-space-6) var(--spacing-space-4) var(--spacing-space-6);
    position: relative;
  }

  & .icon-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  & .home-3 {
    height: 24px;
    position: relative;
    width: 24px;
  }
`;

const headlineStyles = (theme: Theme) => createStyles({
  headline: {
    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.navigation.color,
    alignSelf: 'stretch',
    textAlign: 'right',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    marginTop: '-1px',
    position: 'relative',
  },
});

const NameLabel = withStyles(headlineStyles)(({ classes, displayName }: { classes: any, displayName: string }) => (
  <div className={classes.headline}>{displayName}</div>
));

/** @public */
export const UserSettingsSignInAvatars = () => {
  const { profile, displayName } = useUserProfile();
  const { isOpen } = useSidebarOpenState();

  return (
    <StyledDiv>
      <div className="fieldset">
        <div className="icon-2">
          <Avatar
            className="home-3"
            src={profile.picture}
            alt="Profile picture"
          />
        </div>
        {isOpen && (
          <NameLabel displayName={displayName} />
        )}
      </div>
    </StyledDiv>
  );
};
import React, { ChangeEvent } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import useObservable from 'react-use/esm/useObservable';
//import { makeStyles } from '@material-ui/core/styles';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
import { userSettingsTranslationRef } from './translation';
import { useSidebarOpenState } from '@backstage/core-components';
import styled from "styled-components";
import "./styleguide.css";

const StyledContainer = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  & .state-layer-14 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: var(--spacing-space-2);
    padding: var(--spacing-space-3) var(--spacing-space-6) var(--spacing-space-3) var(--spacing-space-4);
    position: relative;
    width: 100%;
  }

  & .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  & .headline {
    align-self: stretch;
    color: #77767A;
    text-align: right;
    font-family: 'Roboto, sans-serif',
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-top: -1px;
    position: relative;
  }

  & .trailing-element {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }

  & .toggle-off {
    align-self: stretch;
    height: 48px !important;
    position: relative !important;
    width: 48px !important;
  }
`;

/** @public */
export const UserSettingsThemeToggle = () => {
  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = useObservable(
    appThemeApi.activeThemeId$(),
    appThemeApi.getActiveThemeId(),
  );

  const { t } = useTranslationRef(userSettingsTranslationRef);

  const handleSetTheme = (
    _event: ChangeEvent<HTMLInputElement>,
    newThemeId: boolean,
  ) => {
    if (newThemeId) {
      appThemeApi.setActiveThemeId('dark');
    } else {
      appThemeApi.setActiveThemeId('light');
    }
  };

  const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
    }),
  )(Switch);
  
  const headlineStyles = (theme: Theme) => createStyles({
    headline: {
      color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.navigation.color,
      alignSelf: 'stretch',
      textAlign: 'right',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      marginTop: '-1px',
      position: 'relative',
    },
  });

  // Create a styled headline component
  const StyledHeadline = withStyles(headlineStyles)(({ classes, t }: { classes: any, t: any }) => (
    <div className={classes.headline}>{t('themeToggle.names.dark')}</div>
  ));

  const { isOpen } = useSidebarOpenState();

  return (
    <StyledContainer>
      <div className="state-layer-14">
        {isOpen && (
          <div className="content">
            <StyledHeadline t={t} />
          </div>
        )}
        <div className="toggle-off">
          <div className="trailing-element">
            <AntSwitch checked={activeThemeId === 'dark'} onChange={handleSetTheme} name="themeSwitch" />
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};
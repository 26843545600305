import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from './logo/amplifi_logo.png';

const createClasses = makeStyles({
 logo: { marginLeft: 'auto', marginRight: 'auto', width: 'auto', height: 'auto' }
})


const LogoFull = () => {
  const classes = createClasses();
  return <img className={classes.logo} src={logo} />;
};

export default LogoFull;
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  defaultTypography,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

// import { alpha } from '@material-ui/core/styles';
// import { Theme } from '@mui/material/styles';


export const light = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#0048C1',
      },
      secondary: {
        main: '#FFFFFF',
      },
      error: {
        main: '#3C6FEE',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#f6f6f6',
        paper: '#FFFFFF',
      },
      banner: {
        info: '#2E77D0',
        error: '#E22134',
        text: '#FFFFFF',
        link: '#000000',
        closeButtonColor: '#FFFFFF',
        warning: '#FF9800',
      },
      errorBackground: '#3C6FEE',
      warningBackground: '#8f5e15',
      infoBackground: '#D2E0FA',
      navigation: {
        background: '#F7F9FE',
        indicator: '#FD8F40',
        color: '#77767A',
        selectedColor: '#FFFFFF',
        navItem: {
          hoverBackground: '#FFEDE4',
        },
        submenu: {
          background: '#FD8F40',
        },
        },
        pinSidebarButton: {
          icon: '#181818',
          background: '#BDBDBD',
        },
        tabbar: {
          indicator: '#9BF0E1',
        },
      },
  }),
  defaultPageTheme: 'home',
  typography: {
      ...defaultTypography,
      htmlFontSize: 16,
      fontFamily: 'Roboto, Helvetica',
      h1: {
        fontSize: 36,
        fontWeight: 500,
        marginBottom: 10,
      },
      h2: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 8,
      },
      h3: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 14,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 2,
      },
    },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#0048C1', '#003EA8'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#0048C1', '#003EA8'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#0048C1', '#003EA8'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#0048C1', '#003EA8'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#0048C1', '#003EA8'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#0048C1', '#003EA8'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#0048C1', '#003EA8'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#0048C1', '#003EA8'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#0048C1', '#003EA8'], shape: shapes.wave }),
  },
  components: {
    // BackstageHeader: {
    //   styleOverrides: {
    //     header: ({ theme }) => ({
    //       backgroundImage: '.\bar.png',
    //       boxShadow: 'unset',
    //       paddingBottom: theme.spacing(1),
    //     }),
    //     title: ({ theme }) => ({
    //       color: theme.page.fontColor,
    //       fontWeight: 900,
    //     }),
    //     subtitle: ({ theme }) => ({
    //       color: alpha(theme.page.fontColor, 0.8),
    //     }),
    //     type: ({ theme }) => ({
    //       color: alpha(theme.page.fontColor, 0.8),
    //     }),
    //   },
    // },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderTop: '1px solid #A8C1F7',
            background: '#E8EFFD', // Added background color
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)', // Added box-shadow
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
            fontWeight: 'bold', // Added for matching style
            fontSize: '14px', // Added for matching style
            color: '#333333', // Added for matching style
            background: '#E8EFFD', // Added background color to th
          },
          '& tr:nth-of-type(even)': {
            backgroundColor: '#F7F9FE',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#ffffff', // Added for odd rows background color
          },
        },
      },
    },

   BackstageTableToolbar: {
      styleOverrides: {
        root: {
          padding: '24px 0 20px 20px',
          '&> :first-child': {
            fontWeight: 'bold',
          },
        },
        title: {
          '& > h2': {
            fontWeight: 'bold', // Added for matching style
            fontSize: '14px', // Added for matching style
          },
        },
        searchField: {
          paddingRight: '16px',
        },
      },
    },


    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          background: 'linear-gradient(90deg, #FFEDE4 100%, #FFEDE4 100%)', // Gradient background
          color: '#DC7528', // Darker orange color for text
          margin: 4,
        },
      },
    },


    // TODO: (awanlin) - we get a type error for `root`, need to investigate
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#5E5E62',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});
export const dark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#82BAFD',
      },
      secondary: {
        main: '#FF88B2',
      },
      error: {
        main: '#F84C55',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#333333',
        paper: '#424242',
      },
      banner: {
        info: '#2E77D0',
        error: '#E22134',
        text: '#FFFFFF',
        link: '#000000',
        closeButtonColor: '#FFFFFF',
        warning: '#FF9800',
      },
      errorBackground: '#3C6FEE',
      warningBackground: '#8f5e15',
      infoBackground: '#D2E0FA',
      navigation: {
        background: '#252529',
        indicator: '#FD8F40',
        color: '#FFFFFF',
        selectedColor: '#FFFFFF',
        navItem: {
          hoverBackground: '#46464A',
        },
        submenu: {
        background: '#FD8F40',
        },
      },
      pinSidebarButton: {
      icon: '#404040',
      background: '#BDBDBD',
      },
      tabbar: {
        indicator: '#9BF0E1',
      },
    },
  }),
  defaultPageTheme: 'home',
  typography: {
      ...defaultTypography,
      htmlFontSize: 16,
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontSize: 36,
        fontWeight: 500,
        marginBottom: 10,
      },
      h2: {
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 8,
      },
      h3: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 500,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 500,
        fontSize: 18,
        marginBottom: 2,
      },
    },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#404040', '#000000'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#404040', '#000000'],
      shape: shapes.wave,
    }),
    tool: genPageTheme({ colors: ['#404040', '#000000'], shape: shapes.wave }),
    service: genPageTheme({
      colors: ['#404040', '#000000'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#404040', '#000000'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#404040', '#000000'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#404040', '#000000'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#404040', '#000000'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#404040', '#000000'], shape: shapes.wave }),
  },
  components: {
    // BackstageHeader: {
    //   styleOverrides: {
    //     header: ({ theme }) => ({
    //       backgroundImage: '.\bar.png',
    //       boxShadow: 'unset',
    //       paddingBottom: theme.spacing(1),
    //     }),
    //     title: ({ theme }) => ({
    //       color: theme.page.fontColor,
    //       fontWeight: 900,
    //     }),
    //     subtitle: ({ theme }) => ({
    //       color: alpha(theme.page.fontColor, 0.8),
    //     }),
    //     type: ({ theme }) => ({
    //       color: alpha(theme.page.fontColor, 0.8),
    //     }),
    //   },
    // },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderTop: '1px solid #7c7c7c',
            background: '#696969', // Added background color
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)', // Added box-shadow
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
            fontWeight: 'bold', // Added for matching style
            fontSize: '14px', // Added for matching style
            color: '#ffffff', // Added for matching style
            background: '#696969', // Added background color to th
          },
          '& tr:nth-of-type(even)': {
            backgroundColor: '#404040',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#2f2f2f', // Added for odd rows background color
          },
        },
      },
    },

   BackstageTableToolbar: {
      styleOverrides: {
        root: {
          padding: '24px 0 20px 20px',
          '&> :first-child': {
            fontWeight: 'bold',
          },
        },
        title: {
          '& > h2': {
            fontWeight: 'bold', // Added for matching style
            fontSize: '14px', // Added for matching style
          },
        },
        searchField: {
          paddingRight: '16px',
        },
      },
    },


    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#404040',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#404040',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#404040',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#404040',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#404040',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#404040',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#26385A',
            color: '#404040',
          },
          '&[aria-expanded=true] path': {
            fill: '#404040',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          background: 'linear-gradient(90deg, #FFEDE4 100%, #FFEDE4 100%)', // Gradient background
          color: '#DC7528', // Darker orange color for text
          margin: 4,
        },
      },
    },


    // TODO: (awanlin) - we get a type error for `root`, need to investigate
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#404040',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#5E5E62',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});
import { homePlugin } from '@backstage/plugin-home';
import { createReactExtension } from '@backstage/core-plugin-api';

export const MyticketsWidget = homePlugin.provide(
  createReactExtension({
    name: 'MyTicketsWidget',
    component: {
      lazy: () =>
        import('./MyticketWidget').then(m => m.default),
    },
    data: {
      'home.widget.config': {
        layout: {
          width: { defaultColumns: 3, minColumns: 1 },
          height: { defaultRows: 4, minRows: 1 },
        },
      },
    },
  }),
);

export default MyticketsWidget;

import React from 'react';
import { SidebarItem } from '@backstage/core-components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconComponent } from '@backstage/core-plugin-api';
import {
  identityApiRef,
  errorApiRef,
  useApi,
} from '@backstage/core-plugin-api';

/** @public */
export const Logout = () => {
  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);

  return (
    <SidebarItem
      icon={ExitToAppIcon as IconComponent}
      text="Logout"
      onClick={() =>
        identityApi.signOut().catch(error => errorApi.post(error))
      }
    />
  );
};

import React, { PropsWithChildren } from 'react';
import BookIcon from '@material-ui/icons/Book';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import styled from "styled-components";
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { IconComponent } from '@backstage/core-plugin-api';
import AppsIcon from '@material-ui/icons/Apps';
import FolderIcon from '@material-ui/icons/Folder';
import LogoIcon from './LogoIcon';
import LogoFull from './LogoFull';
import { Logout } from '../logout/Logout';
import { UserSettingsThemeToggle } from '../general/UserSettingsThemeToggle';
import { UserSettingsSignInAvatars } from '../general/UserSettingsSignInAvatar';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const menuStyles = (theme: Theme) => createStyles({
  menu: {
    padding: "8px 24px",
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: "0.1px",
    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.navigation.color,
  },
});

const MenuLabel = withStyles(menuStyles)(({ classes, children }: { classes: any, children?: React.ReactNode }) => (
  <div className={classes.menu}>{children || 'Menu'}</div>
));

export const CustomSidebarDivider = styled(SidebarDivider)`
  background: #dcdcdc !important;
`;

// Override selectedIndicatorWidth
sidebarConfig.selectedIndicatorWidth = 224; // Set your desired width here

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <CustomSidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
      <MenuLabel>Menu</MenuLabel>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={AppsIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={FolderIcon} to="docs" text="Docs" />
        <SidebarItem icon={StackedLineChartIcon as IconComponent} to="create" text="Self-Service +" />
        {/* End global nav */}
        <CustomSidebarDivider />
        <MenuLabel>My Apps</MenuLabel>
        <SidebarScrollWrapper>
          <SidebarItem icon={BookIcon} to="docs/default/component/amplifi_document" text="User Manual" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <CustomSidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings" >
      <SidebarSettings />
      <Logout />
      <UserSettingsSignInAvatars />
      </SidebarGroup>
      <CustomSidebarDivider />
      <UserSettingsThemeToggle />
    </Sidebar>
    {children}
  </SidebarPage>
);
